@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Inter:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=PT+Serif:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Karla:regular,bold,italic,500&subset=latin,latin-ext');

@font-face {
  font-family: 'CircularStdBold';
  src: url('../fonts/CircularStd-Bold.otf');
}

@font-face {
  font-family: 'CircularStdMedium';
  src: url('../fonts/CircularStd-Medium.otf');
}

@font-face {
  font-family: 'CircularStd';
  src: url('../fonts/CircularStd-Black.otf');
}

@font-face {
  font-family: 'CircularStdBook';
  src: url('../fonts/CircularStd-Book.otf');
}

:root {
  --primary: #6177EC;
  --secondary: #41509f;
  --primary-text: #5C5C5C;
  --secondary-text: rgba(92, 92, 92, 0.8);
  --alternate-background: #eeeeee;
}

* {
  font-family: 'Inter';
  margin: 0px;
  padding: 0px;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

nav {
  width: 100%;
  padding: 16px 40px;
  height: 100px;
  position: fixed;
  z-index: 20;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid rgba(92, 92, 92, 0.00);
}

nav.scrolled {
  height: 80px;
  border-bottom: 1px solid rgba(92, 92, 92, 0.05);
  background-color: white;
}

nav .item {
  padding: 20px 16px;
  cursor: pointer;

  font-family: Karla, sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 3px;
  line-height: 20px;
  color: var(--primary-text);

  transition: 0.3s;
}

nav .item:hover {
  color: var(--primary);
  transition: 0.3s;
}

nav .item.selected {
  color: var(--primary);
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  gap: 8px;
  margin-top: 40px;
}

.footer .icons {
  display: flex;
  gap: 20px;
}

.footer .icon {
  transition: 0.3s;
  color: var(--primary);
  cursor: pointer;
}

.footer .icon:hover {
  transition: 0.5s;
  color: var(--secondary)
}

.footer .trademark {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  color: var(--primary-text);
}

.link {
  font-family: 'PT Serif', serif;
  font-weight: 600;

  border-style: dotted;
  border-width: 1px 1px 2px;
  border-color: transparent transparent rgba(92, 92, 92, 0.21);
  color: var(--secondary-text);
  cursor: pointer;

  transition: 0.3s;
}

.link:hover {
  font-family: 'PT Serif', serif;

  color: var(--secondary-text);
  border-bottom-color: var(--primary);
  transition: 0.3s;
}

.highlight {
  font-family: Karla, san-serif;
  border-bottom: 4px solid rgba(97, 119, 236, 0.39);
}

.bold {
  font-family: Karla, san-serif;
  font-weight: bold;
}

.alt {
  background-color: var(--alternate-background);
}