.about {
  margin-top: 100px;
  margin-bottom: 60px;
}

.about .info {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.about .content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 640px;
}

.about h1 {
  font-family: 'PT Serif', serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  color: var(--primary-text);

  margin-bottom: 24px;
}

.about p {
  font-family: Karla, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: var(--secondary-text);

  width: 600px;
  text-wrap: wrap;
}

.about img {
  width: 400px;
  border-radius: 10px;
}

.about .buttons {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.about .button {
  font-family: Karla, sans-serif;

  width: auto;
  padding: 8px 16px;
  border: 2px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);

  cursor: pointer;
  transition: 0.3s;
}

.about .button:hover {
  background-color: var(--primary);
  color: white;

  transition: 0.3s;
}
